@font-face {
    font-family: "Isidora Sans Webfont";
    src: url("../public/fonts/isidorasans-medium.woff2") format("woff2"),
    url("../public/fonts/isidorasans-medium.woff") format("woff");
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "Isidora Sans Webfont";
    src: url("../public/fonts/isidorasans-semibold.woff2") format("woff2"),
    url("../public/fonts/isidorasans-semibold.woff") format("woff");
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: "Isidora Sans Webfont";
    src: url("../public/fonts/isidorasans-bold.woff2") format("woff2"),
    url("../public/fonts/isidorasans-bold.woff") format("woff");
    font-style: normal;
    font-weight: 700;
}

* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: auto;
    font-family: "Isidora Sans Webfont", sans-serif;
    overflow: hidden;
}

.sr-only {
    border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;   
}

[hidden]:not([hidden="false"]) {
    visibility: hidden;
}